h1 {
  margin-top: 0;
  margin-bottom: 0;
}

.logo a {
  color: white;
  text-decoration: none;
}

.redText {
  color: red;
}
.greenText {
  color: green;
}
.orangeText {
  color: orange;
}

.countdown {
  font-size: 22px;
  font-weight: bold;;
}

.compBox{
  height: 140px;
}
.compName{
  font-size: 16px;
  font-weight: bold;;
}
.compButton{
  margin-top: 15px;
}
.compMatchStartDate{
  font-size: 16px;
  margin-top: 10px;
}
.compEndDate{
  font-size: 12px;
  margin-top: 5px;
}
.compMatchCup{
  font-size: 20px;
}
.compSponsor{
  margin-top: 5px;
}
.compDynamicWord{
  color: blue;
}

.voteBox{
  height: 40px;
}
.voteName{
  font-size: 16px;
  font-weight: bold;;
}
.voteButton{
  margin-top: 15px;
}
.voteMatchStartDate{
  font-size: 16px;
  margin-top: 10px;
}
.voteMatchCup{
  font-size: 20px;
}
.voteSponsor{
  margin-top: 8px;
}

.rateBox{
  height: 40px;
}
.rateName{
  font-size: 16px;
  font-weight: bold;;
}
.rateStars{
  margin-top: 5px;
}
.rateMatchStartDate{
  font-size: 16px;
  margin-top: 10px;
}
.rateMatchCup{
  font-size: 20px;
}
.rateSponsor{
  margin-top: 8px;
}

.matchStartDate {
  font-size: 12px;
  text-align: right;
  font-weight: bold;
}

.row-red {
  background-color: #f44336;
}
.row-light-red {
  background-color: #ef9a9a;
}
.row-blue {
  background-color: #2196f3;
}
.row-light-blue {
  background-color: #bbdefb;
}
.row-red a {
  color: white;
}
.row-red th {
  color: white;
}
.row-green {
  background-color: #4CAF50;
}